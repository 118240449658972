import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AdminLayout from './layouts/Admin';
import { AuthProvider } from './auth/JWTContext';
import AuthGuard from './auth/authGuard';
import { ReportContextProvider } from './views/ReportsFilter/ReportContext';
import { SettingsProvider } from './contexts/SettingsContext';
import './app.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/winning-partner-admin-react.scss?v=2.0.0';
import './assets/css/demo.css';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CurrencyProvider } from "./contexts/CurrencyContext";

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthGuard>
          <SettingsProvider>
            <CurrencyProvider>
              <ReportContextProvider>
                <AdminLayout />
              </ReportContextProvider>
            </CurrencyProvider>
          </SettingsProvider>
        </AuthGuard>
      </LocalizationProvider>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById('root')
);
