import React, { useState } from 'react';
import useAuth from '../../auth/useAuth';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from '@mui/material';

export const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.login(email, password);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth='sm' sx={{ py: '100px' }}>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3,
              }}
            >
              <div>
                <Typography color='textPrimary' gutterBottom variant='h4'>
                  Log in
                </Typography>
              </div>
            </Box>
            <Box
              component='form'
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
              onSubmit={handleSubmit}
            >
              <TextField
                autoFocus
                fullWidth
                label='Email'
                margin='normal'
                name='email'
                type='text'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant='outlined'
              />
              <TextField
                fullWidth
                label='Password'
                margin='normal'
                name='password'
                type='password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant='outlined'
              />
              <Box sx={{ mt: 2 }}>
                <Button
                  color='primary'
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Log In
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default LoginPage;
