import type { FC } from 'react';
import { AppBarProps, AppBar, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as Logo } from '../../assets/img/WinningPartner Logo.svg';
import { experimentalStyled } from '@mui/material/styles';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const WPLogo = experimentalStyled(Logo)({
  width: '130px',
  height: '30px',
  '& rect': {
    width: '150px',
  }
});

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      [theme.breakpoints.up('lg')]: {
        width: 264,
        height: 64,
        left: '0px !important',
      },
      [theme.breakpoints.down('lg')]: {
        height: 48,
      },
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other} style={{ backgroundColor: '#EEEFFF', paddingTop: '65px' }}>
      <Toolbar
        sx={{
          minHeight: '64px !important',
          px: '32px !important',
          backgroundColor: '#EEEFFF',
        }}
      >
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
        >
          <MenuIcon fontSize="small" sx={{ color: 'black' }} />
        </IconButton>
        <WPLogo />
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
