import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import AdminFooter from '../components/Footers/AdminFooter';
import routes from '../routes';
import { Routes } from 'react-router';
import { ErrorBoundary } from './ErrorBoundary.js';
import { Toaster } from 'react-hot-toast';
import DashboardSidebar from '../components/Sidebar/DashboardSidebar';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { ThemeProvider } from '@mui/material';
import { createCustomTheme } from '../theme/index';
import useSettings from '../hooks/useSettings';
import DashboardNavbar from '../components/Navbars/DashboardNavbar';
import useAuth from "../auth/useAuth";

function Admin() {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(true);
  const { settings } = useSettings();
  const auth = useAuth();
  const isLarge = useMediaQuery(useTheme().breakpoints.up('lg'));
  const padding = isLarge && isSidebarMobileOpen ? '264px' : '0px';

  const getRoutes = (routes) => {
    return routes.filter((prop, key) => {
      if (prop.collapse) {
        return true;
      }
      return prop.authorities
          ? prop.authorities.every(authority => auth.user?.authorities.includes(authority))
          : true;
    }).map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return <Route path={prop.path} key={key} element={prop.component} />;
    });
  };

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <Toaster 
        position="top-center" 
        toastOptions={{
          style: {
            maxWidth: '100%'
          }
        }} 
      />
      <Box sx={{ pl: padding }}>
        <DashboardSidebar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
        <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(!isSidebarMobileOpen)} />
        <Box sx={{ px: '52px', background: '#FAFAFF' }}>
          <ErrorBoundary>
            <Routes>{getRoutes(routes)}</Routes>
          </ErrorBoundary>
        </Box>
      </Box>
      <AdminFooter />
    </ThemeProvider>
  );
}

export default Admin;
