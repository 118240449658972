import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, Theme, useMediaQuery } from '@mui/material';
import NavSection from './NavSection';
import useAuth from "../../auth/useAuth";
import {ROLE_ADMIN} from "../../auth/userAuthorities";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    path: '/',
    title: 'Dashboard',
  },
  {
    path: '/brands',
    title: 'Brands',
  },
  {
    path: '/affiliates',
    title: 'Companies',
  },
  {
    path: '/deals',
    title: 'Affiliate Deals',
  },
  {
    path: '/links',
    title: 'Tracking Links',
  },
  {
    path: '/assets',
    title: 'Media Assets',
  },
  {
    title: 'Events',
    items: [
      {
        path: '/events/clicks',
        title: 'Click Events',
      },
      {
        path: '/events/registrations',
        title: 'Registration Events',
      },
      {
        path: '/events/ftds',
        title: 'FTD Events',
      },
      {
        path: '/events/deposits',
        title: 'Deposit Events',
      },
    ],
  },
  {
    title: 'Reports',
    items: [
      {
        path: '/players',
        title: 'Players',
      },
      {
        path: '/exclusions',
        title: 'Exclusions',
      },
      {
        path: '/affiliates-sources',
        title: 'Affiliate Sources',
      },
      {
        path: '/user-deals',
        title: 'Affiliate Deals',
      },
      {
        path: '/bulk-cohort',
        title: 'Bulk Cohort Report',
      },
      {
        path: '/affiliates-report',
        title: 'Companies',
      },
      {
        path: '/brands-report',
        title: 'Brands',
      }
    ],
  },
  {
    path: '/contact-us-messages',
    title: 'Contact Messages',
  },
  {
    path: '/import-summary',
    title: 'Import Summary',
  },
  {
    path: '/testimonials',
    title: 'Testimonials',
  },
  {
    path: '/operator-management',
    title: 'Operator Management',
    authorities: [ROLE_ADMIN],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const firstRender = useRef(true);
  const location = useLocation();
  const [openSection, setOpenSection] = useState<string | null>(null);
  const auth = useAuth();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  useEffect(() => {
    if (!firstRender.current && openMobile && onMobileClose && !lgUp) {
      onMobileClose();
    }
    firstRender.current = false;
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        pt: '120px',
      }}
    >
      <Box sx={{ p: 4 }}>
        {sections.filter(section => {
          return section.authorities
              ? section.authorities.every(authority => auth.user?.authorities.includes(authority))
              : true;
        }).map((section) => (
          <NavSection
            key={section.title}
            open={section.title === openSection}
            toggleOpen={() => setOpenSection(section.title === openSection ? null : section.title)}
            pathname={location.pathname}
            sx={{
              '& + &': {
                mt: 1,
              },
              p: 0,
            }}
            {...section}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <Drawer
      open={openMobile}
      variant={openMobile ? 'persistent' : undefined}
      anchor="left"
      onClose={onMobileClose}
      PaperProps={{
        sx: {
          backgroundColor: '#EEEFFF',
          overflowY: 'scroll',
          width: 264,
        },
      }}
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
