import React from 'react';
import { Container } from '@mui/material';

function AuthFooter() {
  return (
    <>
      <footer>
        <Container>
          <nav>
            <ul className="footer-menu d-flex justify-content-center"></ul>
            <p className="copyright text-center m-0">
              © {new Date().getFullYear()} <a href="https://www.winningpartner.com">Traffic Label / Winning Partner</a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
