import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { currencyApi } from "../api/currencyApi";

export interface CurrencyContextValue {
    currency: string;
}

const CurrencyContext = createContext<CurrencyContextValue>({
    currency: 'GBP'
});

interface CurrencyProviderProps {
    children: ReactNode;
}

export const CurrencyProvider: FC<CurrencyProviderProps> = (props) => {
    const { children } = props;
    const [currency, setCurrency] = useState('GBP');

    useEffect(() => {
        currencyApi.getSystemCurrency()
            .then(r => setCurrency(r.systemCurrency))
            .catch(() => console.error("Failed to fetch system currency"))
    }, []);

    return (
        <CurrencyContext.Provider value={{currency}}>
            {children}
        </CurrencyContext.Provider>
    );
}

export default CurrencyContext;