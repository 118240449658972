import axios, { AxiosInstance, AxiosResponse } from 'axios';

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected constructor() {
    this.instance = axios.create({});
    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  };

  private handleResponse = ({ data }: AxiosResponse) => data;

  protected handleError = (error: any) => Promise.reject(error);

  public doGet(url: string, signal?: AbortSignal) : Promise<any> {
    this.validateAccessToken();
    return this.instance.get(url, {
      signal,
      headers: {
        Authorization: `Bearer ${typeof window !== 'undefined' && (localStorage.getItem('accessToken'))}`,
      }
    });
  }

  protected doPost(url: string, data?: object, contentType?: string, onUploadProgress?: any) : Promise<any> {
    this.validateAccessToken();
    return this.instance.post(
      url,
      data ?? {},
      {
        headers: {
          ContentType: contentType ?? 'application/json',
          Authorization: `Bearer ${typeof window !== 'undefined' && (localStorage.getItem('accessToken'))}`,
        },
        onUploadProgress
      }
    );
  }

  protected doPut(url: string, data?: object, contentType?: string, onUploadProgress?: any) : Promise<any> {
    this.validateAccessToken();
    return this.instance.put(
      url,
      data ?? {},
      {
        headers: {
          ContentType: contentType ?? 'application/json',
          Authorization: `Bearer ${typeof window !== 'undefined' && (localStorage.getItem('accessToken'))}`,
        },
        onUploadProgress
      }
    );
  }

  protected doDelete(url: string) : Promise<any> {
    this.validateAccessToken();
    return this.instance.delete(
      url,
      {
        headers: {
          Authorization: `Bearer ${typeof window !== 'undefined' && (localStorage.getItem('accessToken'))}`,
        },
      },
    );
  }

  private validateAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && this.isTokenExpired(accessToken)) {
      localStorage.removeItem('accessToken');
      location.reload();
    }
  }

  private isTokenExpired(token: string): boolean {
    const decoded = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  }
}

export default HttpClient;
