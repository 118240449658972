import React from 'react';
import { Container } from '@mui/material';

function AdminFooter() {
  return (
    <>
      <footer className='footer'>
        <Container sx={{ pl: 4, ml: 2 }}>
          <nav>
            <ul className="footer-menu"></ul>
            <p className="copyright text-center">
              © {new Date().getFullYear()}
              <a href="https://trafficlabel.com">{` Traffic Label`}</a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
