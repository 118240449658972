import useAuth from "./useAuth";
import { PropsWithChildren, ReactNode } from "react";
import AuthFooter from "../components/Footers/AuthFooter";
import LoginPage from "../views/Pages/LoginPage";
import { CircularProgress } from "@mui/material";
import { Toaster } from "react-hot-toast";

interface AuthGuardProps {
  children: ReactNode;
}

export default function AuthGuard(props: PropsWithChildren<AuthGuardProps>) {
  const auth = useAuth();

  if (auth.isAuthenticated && auth.user?.authorities?.includes('ROLE_MANAGER')) {
    return props.children;
  } else if (!auth.isInitialized) {
    return <CircularProgress />;
  } else {
    return (
      <div className="wrapper wrapper-full-page">
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              maxWidth: "100%",
            },
          }}
        />
        <LoginPage />
        <AuthFooter />
      </div>
    );
  }
};
