import axios from 'axios';
import { toast } from 'react-hot-toast';

export const toastApiError = (e: any): void => {
  if (axios.isCancel(e)) {
    return;
  }

  console.error("API Error", e);

  if (!e) {
    toast.error("Something went wrong");
    return;
  }

  if (e.response?.status === 400 && e.response?.data?.message) {
    toast.error(e.response.data.message);
    return;
  }

  const errorDetails = {
    code: e.request?.status, 
    route: e.config?.url, 
    msg: typeof e.response?.data === "string" ? e.response.data :
      e.response?.data?.message ??
      e.response?.data?.detail ??
      e.response?.data?.error ??
      e.message ??
      "Request failed"
  };

  toast.error(Object.values(errorDetails).join('\n'));
}

export default toastApiError;
