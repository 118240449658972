import React, { useContext, useState } from "react";
import moment from 'moment/moment';

interface ContextData {
  brands: number[];
  links: number[];
  sources: Source[];
  countries: string[];
  startDate: Date;
  endDate: Date;
  formattedFilter: string;
  datesFilter: string;
  brandsFilter: string;
  sourceFilter: string;
  countryFilter: string;
  updateContext: (data: PartialContextData) => void;
}

export type Source = {
  id: string;
  linkParam: string;
}

export const defaultSources: Source[] = [
  { id: "Tagged", linkParam: "include_tracked_events" },
  { id: "Organic", linkParam: "include_organic_events" }
];

type PartialContextData = Partial<ContextData>;

function formatDate(date: Date) {
  return moment(date).format('YYYY-MM-DD');
}

function formatDates(startDate: Date, endDate: Date) {
  return `date_from=${formatDate(startDate)}&date_to=${formatDate(endDate)}`;
}

function formatBrands(selectedBrands: number[]) {
  return 'brand_ids=' + selectedBrands.join(',');
}

function formatLinks(selectedLinks: number[]) {
  return 'link_ids=' + selectedLinks.join(',');
}

function formatSources(selectedSources: Source[]) {
  return selectedSources.map((source) => `${source.linkParam}=true`).join('&');
}

function formatCountries(selectedCountries: string[]) {
  return 'countries=' + selectedCountries.join(',');
}

const defaultValue: ContextData = {
  brands: [] as number[],
  links: [] as number[],
  sources: defaultSources,
  countries: [] as string[],
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate: new Date(),
  formattedFilter: '',
  datesFilter: '',
  brandsFilter: '',
  sourceFilter: '',
  countryFilter: '',
  updateContext: () => {}
};

const formattedFilter = (defaultValue: any) => {
  return formatDates(defaultValue.startDate, defaultValue.endDate) +
      '&' + formatBrands(defaultValue.brands) +
      '&' + formatLinks(defaultValue.links) +
      '&' + formatSources(defaultValue.sources) +
      '&' + formatCountries(defaultValue.countries)
}

export const ReportContext = React.createContext({
  ...defaultValue,
  formattedFilter: formattedFilter(defaultValue),
  datesFilter: formatDates(defaultValue.startDate, defaultValue.endDate),
  brandsFilter: formatBrands(defaultValue.brands),
  sourceFilter: formatSources(defaultValue.sources),
  countryFilter: formatCountries(defaultValue.countries)
});

export const useReportContext = () => useContext(ReportContext);

export const ReportContextProvider = ({ children }: { children: any }) => {
  const [items, setItems] = useState<ContextData>({
    ...defaultValue,
    formattedFilter: formattedFilter(defaultValue),
    datesFilter: formatDates(defaultValue.startDate, defaultValue.endDate),
    brandsFilter: formatBrands(defaultValue.brands),
    sourceFilter: formatSources(defaultValue.sources),
    countryFilter: formatCountries(defaultValue.countries)
  });

  const update = (newData: PartialContextData) => {
    const obj = {
      ...items,
      ...newData,
    };
    setItems({
      ...obj,
      formattedFilter: formattedFilter(obj),
      datesFilter: formatDates(obj.startDate, obj.endDate),
      brandsFilter: formatBrands(obj.brands),
      sourceFilter: formatSources(obj.sources),
      countryFilter: formatCountries(obj.countries),
      updateContext: update,
    });
  };

  return (
    <>
      <ReportContext.Provider value={{ ...items, updateContext: update }}>
        {children}
      </ReportContext.Provider>
    </>
  );
};
