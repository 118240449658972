import HttpClient from './api';

export interface CurrencyResponse {
    systemCurrency: string;
}

class CurrencyApi extends HttpClient {
    public constructor() {
        super();
    }

    async getSystemCurrency(): Promise<CurrencyResponse> {
        return this.instance.get('/api/v3/currency');
    }
}

export const currencyApi = new CurrencyApi();