import type { FC, ReactNode } from 'react';
import { NavLink, matchPath } from 'react-router-dom';
import type { ListProps } from '@mui/material';
import { Button, Collapse, List, ListItem, ListSubheader, Tooltip } from '@mui/material';
import NavItem from './NavItem';
import { Warning, WarningRounded } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface Item {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  roles?: string | string[];
}

interface NavSectionProps extends ListProps {
  items?: Item[];
  pathname: string;
  title: string;
  open: boolean;
  path?: string;
  toggleOpen: () => void;
}

const renderNavItems = ({ depth = 0, items, pathname }: { items?: Item[]; pathname: string; depth?: number }): JSX.Element => (
  <List sx={{ width: '100%' }} disablePadding>
    {items?.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      [] as JSX.Element[]
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[];
  pathname: string;
  item: Item;
  depth: number;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname
        )
      : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem active={exactMatch} depth={depth} icon={item.icon} key={key} path={item.path} title={item.title} />);
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, pathname, title, open, toggleOpen, path, ...other } = props;

  const handleToggle = (): void => {
    // setOpen((prevOpen) => !prevOpen);
    toggleOpen();
  };

  return (
    <>
      <List
        sx={{
          width: '100%',
        }}
        subheader={
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: 'text.primary',
              lineHeight: '16px',
              px: '17px',
              background: open ? '#5361F5' : 'none',
              borderRadius: '14px',
            }}
          >
            <Button
              endIcon={!items ? <></> : !open ? <ArrowDropDownIcon fontSize="small" /> : <ArrowDropUpIcon fontSize="small" />}
              onClick={handleToggle}
              sx={{
                color: open ? 'white' : 'black',
                fontSize: '14px',
                lineHeight: '17px',
                fontWeight: 500,
                justifyContent: 'flex-start',
                textAlign: 'left',
                width: '100%',
                p: '6px 0',
              }}
              variant="text"
            >
              {items ? (
                title
              ) : (
                <NavLink to={path || ''} style={{ all: 'inherit' }}>
                  {title}
                </NavLink>
              )}
            </Button>
          </ListSubheader>
        }
        {...other}
      >
        <Collapse in={open && !!items}>
          <ListItem sx={{ p: '8px 0' }}>
            {renderNavItems({
              items,
              pathname,
            })}
          </ListItem>
        </Collapse>
      </List>
    </>
  );
};

export default NavSection;
