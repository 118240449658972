// noinspection JSCheckFunctionSignatures

import * as React from 'react';
import { CircularProgress } from "@mui/material";
import {ROLE_ADMIN} from "./auth/userAuthorities";

const Loadable = (Component) => (props) => (
  <React.Suspense fallback={<CircularProgress />}>
    <Component {...props} />
  </React.Suspense>
);

const Dashboard = Loadable(React.lazy(() => import("./views/Dashboard/Dashboard")));
const Brand = Loadable(React.lazy(() => import("./views/Brands/Brand/Brand")));
const Brands = Loadable(React.lazy(() => import("./views/Brands/Brands")));
const ContactUsMessages = Loadable(React.lazy(() => import("./views/ContactUsMessages/ContactUsMessages")));
const Assets = Loadable(React.lazy(() => import("./views/Assets/Assets")));
const ReportsPlayersPage = Loadable(React.lazy(() => import("./views/Reports/ReportsPlayersPage")));
const ReportsExclusionsPage = Loadable(React.lazy(() => import("./views/Reports/ReportsExclusionsPage")));
const ReportsSourcesPage = Loadable(React.lazy(() => import("./views/Reports/ReportsSourcesPage")));
const ReportsDealsPage = Loadable(React.lazy(() => import("./views/Reports/ReportsDealsPage")));
const ReportsCompaniesPage = Loadable(React.lazy(() => import("./views/Reports/ReportsCompaniesPage")));
const ReportsBrandsPage = Loadable(React.lazy(() => import("./views/Reports/ReportsBrandsPage")));
const ReportsBulkCohortpage = Loadable(React.lazy(() => import("./views/Reports/ReportsBulkCohortpage")));
const Deals = Loadable(React.lazy(() => import("./views/Deals/Deals")));
const Deal = Loadable(React.lazy(() => import("./views/Deals/Deal/Deal")));
const DealCreate = Loadable(React.lazy(() => import("./views/Deals/DealCreate/DealCreate")));
const Player = Loadable(React.lazy(() => import("./views/Player/Player")));
const Affiliates = Loadable(React.lazy(() => import("./views/Affiliates/Affiliates")));
const Affiliate = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/Affiliate")));
const AffiliateEvents = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/AffiliateEvents/AffiliateEvents")));
const AffiliatePlayers = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/AffiliatePlayers/AffiliatePlayers")));
const AddNewLink = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/AddNewLink/AddNewLink")));
const LinksDeals = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/Links-Deals/Links-Deals")));
const EditAffiliate = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/EditAffiliate/EditAffiliate")));
const CreateAsset = Loadable(React.lazy(() => import("./views/Assets/CreateAsset/CreateAsset")));
const Links = Loadable(React.lazy(() => import("./views/Links/Links")));
const Link = Loadable(React.lazy(() => import("./views/Links/Link/Link")));
const AffiliateMessages = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/AffiliateMessages/AffiliateMessages")));
const AffiliateMessageCreate = Loadable(React.lazy(() => import("./views/Affiliates/Affiliate/AffiliateMessages/AffiliateMessageCreate/AffiliateMessageCreate")));
const Events = Loadable(React.lazy(() => import("./views/Events/Events")));
const ImportSummary = Loadable(React.lazy(() => import("./views/Import/ImportSummary")));
const Testimonials = Loadable(React.lazy(() => import("./views/Testimonials/Testimonials")));
const OperatorManagement = Loadable(React.lazy(() => import("./views/OperatorManagement/OperatorManagement")));
const OperatorCreate = Loadable(React.lazy(() => import("./views/OperatorManagement/OperatorCreate")));
const OperatorEdit = Loadable(React.lazy(() => import("./views/OperatorManagement/OperatorInfo")));

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "nc-icon nc-album-2",
    component: <Dashboard />,
  },
  {
    path: "/brands",
    name: "Brands",
    icon: "nc-icon nc-vector",
    component: <Brands />,
  },
  {
    path: "/affiliates",
    name: "Companies",
    icon: "nc-icon nc-badge",
    component: <Affiliates />
  },
  {
    path: "/deals",
    name: "Affiliate Deals",
    icon: "nc-icon nc-atom",
    component: <Deals />,
  },
  {
    path: "/deal/:id",
    name: "Deal",
    component: <Deal />,
  },
  {
    path: "/deal-create",
    name: "Deal",
    component: <DealCreate />,
  },
  {
    path: "/links",
    name: "Tracking Links",
    icon: "nc-icon nc-delivery-fast",
    component: <Links />,
  },
  {
    path: "/link/:id",
    name: "Link",
    icon: "nc-icon nc-delivery-fast",
    component: <Link />,
  },
  {
    path: "/brand/:id/view",
    name: "Brand",
    icon: "nc-icon nc-vector",
    component: <Brand />,
  },
  {
    path: "/assets",
    name: "Media Assets",
    icon: "nc-icon nc-album-2",
    component: <Assets />
  },
  {
    path: "/create-asset",
    name: "Create Asset",
    component: <CreateAsset />
  },
  {
    collapse: true,
    name: "Events",
    path: "/events",
    state: "openForms",
    icon: "nc-icon nc-chart-bar-32",
    views: [
      {
        path: "/events/clicks",
        name: "Click Events",
        icon: "nc-icon nc-button-play",
        component: <Events key="clicks" type="clicks" />
      },
      {
        path: "/events/registrations",
        name: "Registration Events",
        icon: "nc-icon nc-single-02",
        component: <Events key="registrations" type="registrations" />
      },
      {
        path: "/events/ftds",
        name: "FTD Events",
        icon: "nc-icon nc-credit-card",
        component: <Events key="ftds" type="ftds" />
      },
      {
        path: "/events/deposits",
        name: "Deposit Events",
        icon: "nc-icon nc-money-coins",
        component: <Events key="deposits" type="deposits" />
      },
    ]
  },
  {
    collapse: true,
    name: "Reports",
    path: "/reports/",
    state: "openReports",
    icon: "nc-icon nc-chart-pie-35",
    views: [
      {
        path: "/players",
        name: "Players",
        icon: "nc-icon nc-circle-09",
        component: <ReportsPlayersPage />
      },      {
        path: "/exclusions",
        name: "Exclusions",
        icon: "nc-icon nc-circle-09",
        component: <ReportsExclusionsPage />
      },
      {
        path: "/affiliates-sources",
        name: "Affiliate Sources",
        icon: "nc-icon nc-spaceship",
        component: <ReportsSourcesPage />
      },
      {
        path: "/user-deals",
        name: "Affiliate Deals",
        icon: "nc-icon nc-spaceship",
        component: <ReportsDealsPage />
      },
      {
        path: "/bulk-cohort",
        name: "Bulk Cohort Report",
        icon: "nc-icon nc-chart",
        component: <ReportsBulkCohortpage />
      },
      {
        path: "/affiliates-report",
        name: "Companies",
        icon: "nc-icon nc-single-02",
        component: <ReportsCompaniesPage />
      },
      {
        path: "/brands-report",
        name: "Brands",
        icon: "nc-icon nc-single-02",
        component: <ReportsBrandsPage />
      },
    ]
  },
  {
    path: "/player/:id",
    name: "Player",
    component: <Player />
  },
  {
    path: "/affiliate/:id",
    name: "Affiliate",
    component: <Affiliate />
  },
  {
    path: "/affiliate/:id/:tab",
    name: "Affiliate Stats",
    component: <Affiliate />
  },
  {
    path: "/affiliate-events/:id/",
    name: "Affiliate Events",
    component: <AffiliateEvents />
  },
  {
    path: "/affiliate-players/:id/",
    name: "Affiliate Players",
    component: <AffiliatePlayers key="ap-p" type="players" />
  },
  {
    path: "/affiliate-brands/:id/",
    name: "Affiliate Brands",
    component: <AffiliatePlayers key="ap-b" type="casinos" />
  },
  {
    path: "/affiliate-cohort-players/:id/",
    name: "Affiliate Players",
    component: <AffiliatePlayers key="ap-c" type="players/cohort" />
  },
  {
    path: "/add-new-link/:id/",
    name: "Add New Link",
    component: <AddNewLink />
  },
  {
    path: "/links-and-deals/:id/",
    name: "Links And Deals",
    component: <LinksDeals />
  },
  {
    path: "/affiliate-messages/:id/",
    name: "Affiliate Messages",
    component: <AffiliateMessages />
  },
  {
    path: "/aff-messages/create/:id/",
    name: "Affiliate Messages Create",
    component: <AffiliateMessageCreate />
  },
  {
    path: "/affiliate/:id/edit",
    name: "Edit Affiliate",
    component: <EditAffiliate />
  },
  {
    path: "/contact-us-messages",
    name: "Contact Messages",
    icon: "nc-icon nc-email-85",
    component: <ContactUsMessages />,
  },
  {
    path: "/import-summary",
    name: "Import Summary",
    component: <ImportSummary />
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    component: <Testimonials />
  },
  {
    path: "/operator-management",
    authorities: [ROLE_ADMIN],
    name: "Operator Management",
    component: <OperatorManagement />
  },
  {
    path: "/operator-create",
    authorities: [ROLE_ADMIN],
    name: "Operator Create",
    component: <OperatorCreate />
  },
  {
    path: "/operator/:id",
    authorities: [ROLE_ADMIN],
    name: "Operator Edit",
    component: <OperatorEdit />,
  },
];
export default routes;
