import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Button, Collapse, ListItem } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

const NavItem: FC<NavItemProps> = (props) => {
  const { active = false, children, depth, icon, info, open: openProp = false, path, title, ...other } = props;
  const [open, setOpen] = useState<boolean>(openProp || false);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 25;

  if (depth > 0) {
    paddingLeft = 25 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0,
          borderRadius: '14px',
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ArrowDropDownIcon fontSize="small" /> : <ArrowDropUpIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: '500',
            fontSize: '14px',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '6px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        borderRadius: '14px',
      }}
    >
      <Button
        startIcon={icon}
        sx={{
          color: 'text.secondary',
          fontWeight: '500',
          fontSize: '14px',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          pr: '8px',
          py: '6px',
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'primary.main',
            },
          }),
        }}
        variant="text"
      >
        <RouterLink to={path || ''} style={{ all: 'inherit' }}>
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </RouterLink>
      </Button>
    </ListItem>
  );
};

export default NavItem;
